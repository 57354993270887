body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f9;
}

/* Container padding and fade-in animation */
.container {
    padding: 0 15px;
}

.fade-in {
    opacity: 0;
    animation: fadeIn 0.5s ease-in forwards; /* Reduced animation time */
}

/* Profile and Social Sections Styling (no background or border) */
.profile-section,
.social-section {
    padding: 20px;
    margin-bottom: 20px;
}

.profile-pic {
    width: 150px;
    height: 150px;
    border-radius: 50%;
}

.intro {
    font-size: 1.1em;
    color: #555;
}

/* Align right section content to bottom */
.social-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.social-section a {
    color: #0056b3; /* Darker blue for Blog, Online CV, and Contact links */
}

/* Optional: if you want to change the hover color as well */
.social-section a:hover {
    color: #003f7f; /* Slightly darker blue on hover */
}

.social-links a {
    color: #333;
    transition: color 0.3s;
}

.social-links a:hover {
    color: #0056b3;
}

.contributions a {
    color: #0056b3;
    text-decoration: none;
    margin-bottom: 10px; /* Increased spacing between company names */
}

.contributions a:hover {
    text-decoration: underline;
}

/* Keyframes for Fade-in Animation */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
